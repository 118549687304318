var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.category && (_vm.category.children || []).length != 0 ? _c('v-list-group', {
    attrs: {
      "value": _vm._selecteds.includes(_vm.category._id),
      "no-action": "",
      "sub-group": "",
      "active-class": "product-category-list--active"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function () {
        return [_c('v-list-item-title', [_vm._v(_vm._s(_vm.category.name))])];
      },
      proxy: true
    }], null, false, 3383494643)
  }, [_c('v-list-item', {
    class: {
      'v-list-item--exact-active': _vm.isOn
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('setCategory', Object.assign({}, _vm.category));
      }
    }
  }, [_c('v-list-item-title', [_vm._v("전체보기")])], 1), _vm._t("default")], 2) : _c('v-list-item', {
    class: {
      'v-list-item--exact-active': _vm.isOn
    },
    on: {
      "click": function ($event) {
        $event.preventDefault();
        $event.stopPropagation();
        return _vm.$emit('setCategory', Object.assign({}, _vm.category));
      }
    }
  }, [_c('v-list-item-title', [_vm._v(_vm._s(_vm.category.name))])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }