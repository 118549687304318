<template>
    <div v-if="$vuetify.breakpoint.xlOnly">
        <v-divider class="grey darken-4" style="border-width: 2px 0 0" />
        <div class="px-md-16px py-20px py-md-30px">
            <filter-categories v-bind="{ brand }" :code="categoryCode" @search="$emit('search')" />
        </div>
        <v-divider />
        <div class="px-md-16px py-20px py-md-30px">
            <filter-prices />
        </div>
        <v-divider />
    </div>

    <div v-else>
        <filter-prices />
    </div>
</template>

<script>
import FilterPrices from "./filter-prices.vue";
import FilterCategories from "./filter-categories.vue";

export default {
    components: {
        FilterPrices,
        FilterCategories,
    },
    props: {
        brand: { type: Object },
        categoryCode: { type: String },
    },
};
</script>

<style></style>
