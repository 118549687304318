var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.$vuetify.breakpoint.xlOnly ? _c('div', [_c('v-divider', {
    staticClass: "grey darken-4",
    staticStyle: {
      "border-width": "2px 0 0"
    }
  }), _c('div', {
    staticClass: "px-md-16px py-20px py-md-30px"
  }, [_c('filter-categories', _vm._b({
    attrs: {
      "code": _vm.categoryCode
    },
    on: {
      "search": function ($event) {
        return _vm.$emit('search');
      }
    }
  }, 'filter-categories', {
    brand: _vm.brand
  }, false))], 1), _c('v-divider'), _c('div', {
    staticClass: "px-md-16px py-20px py-md-30px"
  }, [_c('filter-prices')], 1), _c('v-divider')], 1) : _c('div', [_c('filter-prices')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }