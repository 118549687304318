<template>
    <div v-if="$vuetify.breakpoint.xlOnly">
        <div class="tit-wrap tit-wrap--sm">
            <h3 class="tit tit--xs">가격</h3>
        </div>
        <v-radio-group :value="activeIndex" hide-details>
            <template v-for="({ label, value }, index) in items">
                <v-radio :key="label" v-bind="{ ...radio_primary, label, value: index }" @click="change(value)" />
            </template>
        </v-radio-group>
    </div>

    <div v-else>
        <v-select :value="active" v-bind="{ ...attrs_input }" :items="[{ label: '전체' }].concat(items)" v-on="{ change }" item-text="label" item-value="value" placeholder="가격" class="mw-md-220px ml-auto" />
    </div>
</template>

<script>
import { attrs_input } from "@/assets/variables";
import { radio_primary } from "@/assets/variables";

const prices = [
    [0, 10000],
    [10000, 50000],
    [50000, 100000],
    [100000, 300000],
    [300000, 500000],
    [500000, 1000000],
    [1000000, 10000000],
];
export default {
    data() {
        return {
            radio_primary,

            attrs_input,
        };
    },
    computed: {
        items() {
            return prices.map((value) => ({ label: `${value[0]?.format?.()}원 ~ ${value[1]?.format?.()}원`, value }));
        },
        activeIndex() {
            const { prices } = this.$route.query;
            return (
                this.items.findIndex(({ value }) => {
                    const isSame = value[0] == prices?.[0] && value[1] == prices?.[1];
                    return isSame;
                }) ?? "전체"
            );
        },
        active() {
            const { prices } = this.$route.query;
            return this.items.find(({ value }) => value[0] == prices?.[0] && value[1] == prices?.[1]) || "전체";
        },
    },
    methods: {
        change(value) {
            const { ...query } = this.$route.query;

            delete query.page;

            query.prices = value;

            if (!query.prices || query.prices == "전체") delete query.prices;
            if (this.$route.query.prices == query.prices) delete query.prices;
            if (this.$route.query.prices?.[0] == query.prices?.[0] && this.$route.query.prices?.[1] == query.prices?.[1]) delete query.prices;

            this.$router.push({ query });
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .v-radio {
        &.v-item--active {
            .v-label {
                color: var(--v-grey-darken4);
            }
        }
    }
}
</style>
